import PurchaseListLotus from '../../../components/PurchaseListLotus';
import Location from '../../../components/Location.jsx'
import LogisiticsDetails from '../../../components/LogisiticsDetails';
import 'animate.css';
import StantonLogo from '../../../static/Stanton/StantonLogo.png';
import {StantonDrills, StantonInfo, StantonOption1IndividualSessions, StantonOption2IndividualSessions, StantonOption1Bundle, StantonOption2Bundle} from './StantonData'
import './stanton.scss'
import '../../../App.scss'

export default function Stanton() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
    <div className="info camps__container animate__animated animate__fadeInRight">
      <h4>Wednesday Enrichment at Stanton School - Grades 5 to 8 </h4>
      <h4 className='disclaimer'>No CLass 1/1, 2/26, 3/5 and 3/26. Next Registration Closes 1/1!</h4>
      <h4 className='disclaimer'>This afterschool program is for Stanton School Students only</h4>
      {/* <h4 className='disclaimer'>Registration Extended Until 9/3 Midnight!</h4> */}
      <div className='lotus__container'>
        <div className="purchase">
          <PurchaseListLotus 
            type="bundle-option2"
            header="Entire School Year 1:30-4:00pm"
            subhead="1 payment of $305 // $40 in savings!"
            data={StantonOption1Bundle}
          />
          <PurchaseListLotus 
            type="bundle-option1"
            header="Enitre School Year 1:30-2:45pm"
            subhead="1 payment of $205 //Over $10 in savings!"
            data={StantonOption2Bundle}
          />
          <PurchaseListLotus
            type="individual-option2"
            header="6 Classes from 1:30-4:00pm"
            subhead="$115/ 6 Sessions"
            data={StantonOption1IndividualSessions}
          />
          <PurchaseListLotus 
            type="individual-option1"
            header="6 Classes from 1:30-2:45pm"
            subhead="$72/ 6 Sessions"
            data={StantonOption2IndividualSessions}
          />

        </div>

        <div className='logistics'>
        <img
              className="img-fluid mb-4 pl-0"
              src={StantonLogo}
              alt="Stanton Logo"
            />
          <Location 
            link="https://maps.app.goo.gl/ozk912U3v1aSGZ3W7"
            name="Stanton School"
            street="101 Hawthorne Ln, "
            city="Fox Lake, IL 60020"
          />
          <LogisiticsDetails 
            header="Important Notes"
            data={StantonInfo}
          />
          <LogisiticsDetails 
            header="Skills & Drills Focus"
            data={StantonDrills}        
          />
        </div>

      </div>
      {/* close camp container div */}
    </div>
    {/* close info div */}
  </section>
)
}