import React, { Component } from 'react';
// import Text from './Text'
import Current from './Current'
import Carousel from './Carousel'
import Video from './Video'
import './HomePage'
import 'animate.css';
import FooterPartner from '../../FooterPartner/FooterPartner';

class HomePage extends Component {
  render() {
    return (
      <section className="section d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUp" data-testid="HomePage">
        <div className="info">
          <Carousel />
        </div>
        <div className='current__container'>
          <Current />
          <div className='current-vid__container'>
            <Video url="https://youtu.be/1IxGmeSGUmU"/>
          </div>
        </div>
        <div className='video__container'>
          <h5 className='video-preview'>Watch our flag ceremony for USA Olympian and WNBA champion superstar Jewell Loyd</h5>
          <Video url="https://youtu.be/6szEJePc2J0"/>
        </div>
        <FooterPartner />
      </section>
    );
  }
}

export default HomePage;