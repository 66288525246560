const grasslakeFall2023option1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/grass-lake-after-school-fridays-115-to-400pm-grass/",
    name: "18 Enrichment Sessions",
    date: "1:15-4:00 PM",
  },
]

const grasslakeFall2023option2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/grass-lake-after-school-fridays-115-to-245pm-grass/",
    name: "18 Enrichment Sessions",
    date: "1:15-2:45 PM",
  },
]
const grasslakeFall2023option1IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/dec-to-jan-grass-lake-after-school-fridays-115-to-/",
    name: "December 6th",
    date: "January 31st",
  },
  {
    link: "https://playyon.com/606-academy/programs/feb-7-to-apr-4-grass-lake-after-school-fridays-115/",
    name: "February 7th",
    date: "April 4th",
  },
  {
    link: "https://playyon.com/606-academy/programs/apr-11-to-may-23-grass-lake-after-school-fridays-1/",
    name: "April 11th",
    date: "May 23rd",
  },
]

const grasslakeFall2023option2IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/dec-to-jan-grass-lake-after-school-fridays-115-t-2/",
    name: "December 6th",
    date: "January 31st",
  },
  {
    link: "https://playyon.com/606-academy/programs/feb-7th-to-apr-4th-grass-lake-after-school-fridays/",
    name: "February 7th",
    date: "April 4th",
  },
  {
    link: "https://playyon.com/606-academy/programs/apr-11th-to-may-23rd-grass-lake-after-school-frida/",
    name: "April 11th",
    date: "May 23rd",
  },
]


const grasslakeFall2023AInfo = [
  "Registration closes on 9/27 for October sessions scheduled to start 10/4 pending registrations!",
  "Registration after deadline subject to late registration fees.",
  "No class held on 11/22; 11/29; 12/20; 12/27; 1/3; 2/14; 3/21; 3/28; 4/18;",
  "ONLY Grass Lake School students are eligible to sign up for this enrichment program",
  "Individual day registrations are available upon request.",
  "Individual day registration starts at $35 from 1:15 to 2:45PM; $50 from 1:15 to 4:00PM",
  "Held on early release Fridays",
  "Bring athletic shoes and a water bottle.",
  "K to 8th Grade welcome",
]

const grasslakeFall2023Drills = [
  "Basketball",
  "Soccer",
  "Dodgeball",
  "Volleyball",
  "Sharks and Minnows",
  "Builders and Wreckers",
  "Four Corners",
  "Capture the Flag",
  "Fun at the Park",
  "Floor Hockey",
  "Wall Ball",
  "Sleeping Dragon",
  "Hangout with Friends",
  "and much much more!"
]



export {grasslakeFall2023Drills, grasslakeFall2023AInfo, grasslakeFall2023option1IndividualSessions, grasslakeFall2023option2IndividualSessions, grasslakeFall2023option1Bundle, grasslakeFall2023option2Bundle}