import './About.scss'
import {valuesData} from './ValuesText'
import Video from '../HomePage/Video'
import 'animate.css';

function Values() {
    return (
      <section className="section d-flex flex-column align-items-center justify-content-center flex-md-row justify-content-md-start align-items-md-start flex-lg-row justify-content-lg-start align-items-lg-start">
        <div id="about-values" className="info animate__animated animate__fadeInUp">
          <div className="values-container">
            <h4>Core Values</h4>
            <ul className="list-group list-group-flush values-list">
              {valuesData && valuesData.length > 0 && valuesData.map((item) => 
                <li key={item} className="values-list-item">
                  {item}
                </li>
              )}  
            </ul>
          </div>
          <div className="values-container">
            <h5>Watch our core values in action</h5>
            <Video url="https://youtu.be/dRjmFSSqMes"/>
          </div>
        </div>
      </section>

    );
 };

export default Values;