import './HomePage.scss'

export default function Current() {
   return (
      <div className="d-flex flex-column justify-content-start align-items-center current-comp-container">
        <div className="current-btn__container">
          <h4 className='current-head'>Now Available</h4>
          <a className="home-btn current-btn btn-lg current2-btn" href="/Training1" role="button">Basketball   T-W-TH</a>
          <hr />
          <a className="home-btn current-btn btn-lg current2-btn" href="/FootBallTrainingThreeToSix0" role="button">Football Grades 3 to 6</a>
          <hr />
          <a className="home-btn current-btn btn-lg current2-btn" href="/FootBallTrainingSevenToTwelve0" role="button">Football Grades 7 to 12</a>
        </div>
      </div>
    )
}