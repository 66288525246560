const trainingOption1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/basketball-training-sep-4th-5th-11th-12th-19th/",
    name: "5 Day Bundle",
    date: "7:30-9:00 PM",
  },
]

const trainingOption3Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/basketball-training-sep-11th-12th-19th/",
    name: "3 Day Bundle",
    date: "7:30PM to 9:00 PM",
  },
]


const FourthTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/basketball-training-sep-4th-730-to-900pm/",
    name: "September 4th",
    date: "$30",
  },
]


const FifthTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/basketball-training-sep-5th-730-to-900pm/",
    name: "September 5th",
    date: "$30",
  },
]

const ElleventhTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/basketball-training-sep-11th-730-to-900pm/",
    name: "September 11th",
    date: "$30",
  },
]

const TwelvethTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/basketball-training-sep-12th-730-to-900pm/",
    name: "September 12th",
    date: "$30",
  },
]

const NineteenthTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/basketball-training-sep-19th-730-to-900pm/",
    name: "September 19th",
    date: "$30",
  },
]


const trainingInfo = [
  "Bring a basketball and a water bottle.",
  "3rd Grade to 11th Grade",
]

const trainingDrills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {trainingDrills, trainingInfo, FourthTrainingOption, FifthTrainingOption, ElleventhTrainingOption, TwelvethTrainingOption, NineteenthTrainingOption, trainingOption1Bundle, trainingOption3Bundle}