import {storyData} from './StoryText'
import Video from '../HomePage/Video'
import 'animate.css';

export function Story() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center flex-md-row justify-content-md-start align-items-md-start flex-lg-row justify-content-lg-start align-items-lg-start">
      <div id="about-story" className="info animate__animated animate__fadeInUp">
        <div className="story-container left-col">
          <h4>Our Story</h4>
          { storyData && storyData.length > 0 && storyData.map((item) => 
            <p key={item}>{item}</p>
          )}
        </div>
        <div className="story-container">
          <h5 >Watch Coach Greg share 606 Academy's origin story at 2022 Spring Training</h5>
          <Video url="https://youtu.be/rTAt3dZynmU"/>
        </div>
      </div>
    </section>
  );
};