const trainingOption1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/football-training-december-3rd-to-jan-16th/",
    name: "3 Day Bundle T/W/Th",
    date: "6:00PM to 7:30 PM",
  },
]

const trainingOption2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/football-training-december-3rd-to-jan-16th/",
    name: "2 Day Bundle T/W/Th",
    date: "6:00PM to 7:30 PM",
  },
]

const tuesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/football-training-december-3rd-to-jan-16th/",
    name: "December 3rd",
    date: "Janurary 14th",
  },
]


const wednesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/football-training-december-3rd-to-jan-16th/",
    name: "December 4th",
    date: "January 15th",
  },
]

const thursdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/football-training-december-3rd-to-jan-16th/",
    name: "December 5th",
    date: "January 16th",
  },
]


const trainingInfo = [
  "No training on Dec 10, 11, 18, 24, 25, 26, 31, Jan 1, and 2",
  "Registration closes one week prior to start date",
  "Individual day registration starts at $45 and available upon request",
  "Bring a football and a water bottle.",
  "7th Grade to 12th Grade",
]

const trainingDrills = [
  "Quarterback: Footwork, Proper Throwing Technique, Speed and Agility, Ball Placement",
  "Wide Receiver: Route Running, Ball Protection, Footwork, Speed and Agility, Catching the Ball, Ball Tracking",
  "Running Back: Footwork, Ball Protection, Catching the Ball, Speed and Agility, Explosive Backfield Drills"
]



export {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption,trainingOption1Bundle, trainingOption2Bundle}