import {trainingDrills, trainingInfo, NineteenthTrainingOption,} from './PopInTrainingData'
import PurchaseList from '../../components/PurchaseList';
import Location from '../../components/Location.jsx'
import LogisiticsDetails from '../../components/LogisiticsDetails';
import 'animate.css';

export default function PopInTraining() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
      <div className="info camps__container animate__animated animate__fadeInRight">
        <h4>Basketball Training September 11th, 12th, and 19th</h4>
        <div className='camp__container'>
          <div className="purchase">
          <PurchaseList 
            type="ThreeDayBundle"
            header="September 19th from 7:30-9:00pm"
            subhead="$30/ 1 Class!"
            data={NineteenthTrainingOption}
          />
        </div>

        <div className='logistics'>
        <Location 
              link="https://maps.app.goo.gl/7MJcF7yg9kwpUcrv7"
              name="Stanton School"
              street="101 Hawthorne Ln"
              city="Fox Lake, IL 60020,"
            />
          <LogisiticsDetails 
            header="Important Notes"
            data={trainingInfo}
          />
          <LogisiticsDetails 
            header="Skills & Drills Focus"
            data={trainingDrills}        
          />
        </div>

      </div>
      {/* close camp container div */}
    </div>
    {/* close info div */}
  </section>
)
}