const StantonOption1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/18-sessions-at-stanton-school-january-8th-to-may-2/",
    name: "18 Enrichment Sessions",
    date: "1:30-4:00 PM",
  },
]

const StantonOption2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/18-sessions-at-stanton-school-january-8th-to-may-2/",
    name: "18 Enrichment Sessions",
    date: "1:30-2:45 PM",
  },
]

const StantonOption1IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/january-8th-to-february-12th/",
    name: "January 8th",
    date: "February 12th",
  },
  {
    link: "https://playyon.com/606-academy/programs/february-19th-to-april-16th/",
    name: "February 19th",
    date: "April 16th",
  },
  {
    link: "https://playyon.com/606-academy/programs/april-23rd-to-may-28th/",
    name: "April 23rd",
    date: "May 28th",
  },
]

const StantonOption2IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/january-8th-to-february-12th/",
    name: "January 8th",
    date: "February 12th",
  },
  {
    link: "https://playyon.com/606-academy/programs/february-19th-to-april-16th/",
    name: "February 19th",
    date: "April 16th",
  },
  {
    link: "https://playyon.com/606-academy/programs/april-23rd-to-may-28th/",
    name: "April 23rd",
    date: "May 28th",
  },
]


const StantonInfo = [
  "Registration closes one week prior to start date.",
  "No class held on 1/1, 2/26, 3/5 and 3/26.",
  "Registration after deadline subject to late registration fees.",
  "ONLY Stanton students are eligible to sign up for this enrichment program.",
  "Individual day registrations are available upon request.",
  "Individual day registration starts at $35 from 1:30 to 2:45PM; $50 or from 1:30 to 4:00PM.",
  "Held on early release Wednesdays",
  "Bring a basketball and a water bottle.",
  "5th to 8th grade welcome",
]

const StantonDrills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {StantonDrills, StantonInfo, StantonOption1IndividualSessions, StantonOption2IndividualSessions, StantonOption1Bundle, StantonOption2Bundle}